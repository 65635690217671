import React, { FC, memo, useContext, useMemo, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Typography, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MilestoneListColumnTypeV2 } from './common';
import { CommonRowType, IMilestoneTag, PermissionNamesEnums } from '@interfaces';
import { DrawerContext } from '../drawer';
import { WithPermission } from '@utils';
import { colors } from '@theme';
import { LineItemTagChip } from '@components';

const LineItemTags: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source, milestoneTags, addMilestoneTag, removeMilestoneTag } = useContext(DrawerContext);
  const [tags, setTags] = useState<IMilestoneTag[]>(row.tags || []);
  const [newTagName, setNewTagName] = useState('');

  const availableTags = useMemo(
    () =>
      milestoneTags
        ?.filter((tag) => !tags.some((t) => t.id === tag.id))
        .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [milestoneTags, tags],
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTagSelect = async (tagId: string, tagName: string) => {
    const newTag = await addMilestoneTag(row.id, { id: tagId, name: tagName });
    setTags([...tags, { id: newTag.id, name: tagName }]);
    handleClose();
  };

  const handleRemoveTag = async (tagId: string) => {
    await removeMilestoneTag(row.id, tagId);
    setTags(tags.filter((tag) => tag.id !== tagId));
    handleClose();
  };

  const handleCreateNewTag = async () => {
    if (newTagName.trim()) {
      const newTag = await addMilestoneTag(row.id, { name: newTagName.trim() });
      setTags([...tags, { id: newTag.id, name: newTagName.trim() }]);
      setNewTagName('');
      handleClose();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        flexWrap: 'nowrap',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', gap: 0.5, overflow: 'auto', flexWrap: 'nowrap' }}>
        {tags.map((tag, index) => (
          <LineItemTagChip
            key={tag.id}
            tag={tag}
            colorIndex={index}
            onClick={handleClick}
            data-cy={`${source}__body__line_item_tags__chip`}
          />
        ))}
      </Box>
      {!tags.length && (
        <WithPermission permissionKey={[PermissionNamesEnums.PROJECT_BUDGET_EDIT]}>
          <IconButton
            size="small"
            onClick={handleClick}
            data-cy={`${source}__body__line_item_tags__icon`}
            sx={{ ml: 0.5, flexShrink: 0 }}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </WithPermission>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ maxHeight: 300 }}>
        {tags.map((tag, index) => (
          <MenuItem
            key={tag.id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              py: 0.5,
            }}
            data-cy={`${source}__body__line_item_tags__remove_item`}
          >
            <LineItemTagChip
              tag={tag}
              colorIndex={index}
              onDelete={(e) => {
                e.stopPropagation();
                handleRemoveTag(tag.id);
              }}
            />
          </MenuItem>
        ))}
        <Divider sx={{ my: 1 }} />
        <Box sx={{ px: 2, py: 1 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <input
              type="text"
              value={newTagName}
              onChange={(e) => setNewTagName(e.target.value)}
              placeholder="New tag name"
              style={{
                padding: '8px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                width: '100%',
              }}
              data-cy={`${source}__body__line_item_tags__new_tag_input`}
            />
            <IconButton
              size="small"
              onClick={handleCreateNewTag}
              disabled={!newTagName.trim()}
              data-cy={`${source}__body__line_item_tags__create_tag_button`}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        {availableTags.length > 0 && (
          <>
            <Divider sx={{ my: 1 }} />
            <Typography variant="labelSemiBold" sx={{ m: 1, color: colors.text.medium }}>
              Select an option or create one
            </Typography>
            {availableTags.map((tag, index) => (
              <MenuItem
                key={tag.id}
                onClick={() => handleTagSelect(tag.id, tag.name)}
                data-cy={`${source}__body__line_item_tags__menu_item`}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  py: 0.5,
                }}
              >
                <LineItemTagChip
                  tag={tag}
                  colorIndex={index}
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTagSelect(tag.id, tag.name);
                  }}
                />
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
    </Box>
  );
};

const Memoized = memo(LineItemTags);

const lineItemTagsColumn: MilestoneListColumnTypeV2 = {
  field: 'tags',
  headerName: 'Line item tags',
  flex: 1,
  minWidth: 200,
  editable: false,
  sortable: false,
  renderCell: (params) => <Memoized row={params.row} />,
};

export default lineItemTagsColumn;
