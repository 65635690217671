import {
  BulkDrawRequestListItemUpdateParam,
  BulkDrawRequestPathParam,
  ChangeDrawRequestDocumentParam,
  DeleteDrawRequestListItem,
  DeletePhotoPayload,
  IActivityLogItem,
  IDocument,
  IDrawRequest,
  IDrawRequestFundingSource,
  IDrawRequestsStats,
  IMilestone,
  IMilestoneColumn,
  IMilestonePhotos,
  IMilestoneWithPhotos,
  IRequestReview,
  LineItemPostPayload,
  LineItemPostResponse,
  MarkRequestAsPaidPayload,
  PatchDrawRequestListItemParam,
  PatchDrawRequestMSGroupParam,
  PatchDrawRequestParam,
  PostDrawRequest,
  PostDrawRequestReviewParam,
  RequestPayload,
  SubmitDrawrequestRequestPayload,
  UpdateDrawRequestFSParam,
  UpdateDrawRequestListItemParam,
  UpdateRequestDataPayload,
  UpdateRequestPayload,
} from '@interfaces';
import { dataProvider, dataProviderV2 } from '@api';

import {
  drawRequestAuditLog,
  drawRequestBatchUpdate,
  drawRequestBulkUpdate,
  drawRequestDocument,
  drawRequestDocumentRestore,
  drawRequestDocuments,
  drawRequestFundingSource,
  drawRequestItem,
  drawRequestList,
  drawRequestListStats,
  drawRequestMilestoneColumns,
  drawRequestMilestoneGroups,
  drawRequestMilestones,
  drawRequestMilestonesWithPhotos,
  drawRequestPhotos,
  drawRequestSources,
  getWaitingYourApprovalPath,
  markRequestAsPaidPath,
  milestoneAuditLog,
  milestoneBulkUpdate,
  milestoneDocuments,
  milestoneGroupDocuments,
  postReviewPath,
  projectAuditLog,
  projectDrawRequestList,
  projectDrawRequestPost,
  projectPhotoById,
  propjectDrawRequestReport,
  requestMilestoneById,
  requestMilestoneGroupById,
  requestReviews,
} from '../paths';
import isNumber from 'lodash/isNumber';
import pickBy from 'lodash/pickBy';
import { InterfaceGetDrawRequestMilestones } from '@globalService';
import { defaultDrawRequestQueryDetails } from '@constants';

export const getDrawRequestList = async ({
  queryURL,
}: {
  queryURL: string;
}): Promise<{ results: IDrawRequest[]; count: number }> => {
  return dataProviderV2.get(drawRequestList(queryURL)).json();
};

export const getDrawRequestListStats = async (queryURL: string): Promise<IDrawRequestsStats> =>
  dataProvider.get(drawRequestListStats(queryURL)).json();

export const getWaitingYourApproval = async (id: string): Promise<{ results: IDrawRequest[] }> =>
  dataProvider.get(getWaitingYourApprovalPath(id)).json();

export const getProjectDrawRequestsList = async (
  id: string,
): Promise<{ results: IDrawRequest[] }> => dataProvider.get(projectDrawRequestList(id)).json();

export const getDrawRequest = async ({
  projectId,
  drawRequestId,
  query = `{-comments_preview,${defaultDrawRequestQueryDetails},totals{all}}`,
  groupByKeys = '',
}): Promise<IDrawRequest> => {
  const restQlQuery = query ? `query=${query}&` : '';

  //we need groupByQuery for grouping errors for PHB projects on BE side
  const groupByQuery = groupByKeys ? `group_by=${groupByKeys}&` : '';

  return dataProvider
    .get(
      drawRequestItem({
        projectId,
        drawRequestId,
        restQlQuery,
        groupByQuery,
      }),
    )
    .json();
};
export const getDrawRequestMilestones = async ({
  projectId,
  drawRequestId,
  groupBy: group_by,
  limit = '999',
  filterKey,
  typeKeys,
  restQlParams,
  milestoneTags,
}: InterfaceGetDrawRequestMilestones): Promise<{ results: IMilestone[]; count: number }> => {
  const notEmptyParams = pickBy({ group_by, limit }, Boolean);
  const queryFiltersParams = new URLSearchParams(notEmptyParams).toString();
  const filterParam = filterKey ? `${filterKey}=true&` : '';
  const typeKeysParams = typeKeys ? `&${typeKeys}&` : '';
  const restQlquery = restQlParams ? `query=${restQlParams}&` : '';
  const milestoneTagsParams = milestoneTags ? `milestone_tags=${milestoneTags}&` : '';
  return dataProvider
    .get(
      drawRequestMilestones(
        projectId,
        drawRequestId,
        queryFiltersParams,
        filterParam,
        restQlquery,
        milestoneTagsParams,
        typeKeysParams,
      ),
    )
    .json();
};

export const getDrawRequestMilestoneGroups = async ({
  projectId,
  drawRequestId,
  groupBy: group_by,
  limit = '999',
  filterKey,
  typeKeys,
  restQlParams,
  milestoneTags,
}: InterfaceGetDrawRequestMilestones): Promise<{ results: IMilestone[]; count: number }> => {
  const notEmptyParams = pickBy({ group_by, limit }, Boolean);
  const queryFiltersParams = new URLSearchParams(notEmptyParams).toString();
  const filterParam = filterKey ? `${filterKey}=true&` : '';
  const typeKeysParams = typeKeys ? `&${typeKeys}&` : '';
  const restQlquery = restQlParams ? `query=${restQlParams}&` : '';
  const milestoneTagsParams = milestoneTags ? `milestone_tags=${milestoneTags}&` : '';
  return dataProvider
    .get(
      drawRequestMilestoneGroups(
        projectId,
        drawRequestId,
        queryFiltersParams,
        filterParam,
        restQlquery,
        milestoneTagsParams,
        typeKeysParams,
      ),
    )
    .json();
};

export const getDrawRequestMilestoneListColumns = async (
  projectId,
  drawRequestId,
): Promise<IMilestoneColumn[]> =>
  await dataProvider.get(drawRequestMilestoneColumns(projectId, drawRequestId)).json();

export const postDrawRequestReport = async ({
  project,
  drawRequest,
}: RequestPayload): Promise<IDrawRequest> =>
  dataProvider.post(propjectDrawRequestReport(project, drawRequest)).json();

export const postDrawRequest = async ({
  id,
  type,
  source,
}: PostDrawRequest): Promise<IDrawRequest> =>
  dataProvider
    .post(projectDrawRequestPost(id), {
      json: { type, ...(source && { source }) },
    })
    .json();

export const postDrawRequestReview = async ({
  id,
  drawRequest,
  status,
  next_reviewer_team_elevator,
  next_team_reviewer,
  notes,
}: PostDrawRequestReviewParam): Promise<Response> =>
  dataProvider.post(postReviewPath(id, drawRequest.toString()), {
    json: {
      notes,
      ...(status && { status }),
      ...(next_reviewer_team_elevator && { next_reviewer_team_elevator }),
      ...(next_team_reviewer && { next_team_reviewer }),
    },
  });

export const patchDrawRequest = async ({
  id,
  drawRequest,
  is_on_hold,
  is_on_hold_change_reason,
  fees,
  borrower_equity_rate,
  construction_holdback_rate,
  payment_configuration_comment,
  payment_configuration_type,
  external_transaction_id,
  disbursed_at,
  construction_holdback,
  borrower_equity,
  sources,
}: PatchDrawRequestParam): Promise<Response> =>
  dataProvider.put(drawRequestItem({ projectId: id, drawRequestId: drawRequest.toString() }), {
    json: {
      ...(is_on_hold !== undefined && { is_on_hold }),
      ...(is_on_hold_change_reason && { is_on_hold_change_reason }),
      ...(fees && { fees }),
      ...(borrower_equity_rate >= 0 && { borrower_equity_rate }),
      ...(construction_holdback_rate >= 0 && { construction_holdback_rate }),
      ...(payment_configuration_comment && { payment_configuration_comment }),
      ...(payment_configuration_type && { payment_configuration_type }),
      ...(external_transaction_id && { external_transaction_id }),
      ...(disbursed_at && { disbursed_at }),
      ...(construction_holdback >= 0 && { construction_holdback }),
      ...(borrower_equity >= 0 && { borrower_equity }),
      ...(sources && { sources }),
    },
  });

export const patchDrawRequestListItem = ({
  project,
  milestone,
  drawRequest,
  json,
}: PatchDrawRequestListItemParam): Promise<IMilestone> =>
  dataProvider
    .patch(requestMilestoneById(project, drawRequest, milestone), {
      json,
    })
    .json();

export const pathcDrawRequestBulk = ({
  projectId,
  drawRequestId,
  json,
}: BulkDrawRequestPathParam): Promise<IDrawRequest> =>
  dataProvider
    .patch(drawRequestBulkUpdate(projectId, drawRequestId), {
      json,
    })
    .json();

export const pathcDrawRequestBatch = ({
  projectId,
  drawRequestId,
  json,
}: BulkDrawRequestPathParam): Promise<IDrawRequest> =>
  dataProvider
    .patch(drawRequestBatchUpdate(projectId, drawRequestId), {
      json,
    })
    .json();

export const patchDrawRequestMilestoneGroup = ({
  project,
  drawRequest,
  milestoneId,
  group_by,
  json,
}: PatchDrawRequestMSGroupParam): Promise<IMilestone> => {
  return dataProvider
    .patch(
      requestMilestoneGroupById({
        projectId: project,
        drawRequestId: drawRequest,
        group_by,
        milestoneId,
      }),
      {
        json,
      },
    )
    .json();
};

export const updateDrawRequestMilestones = ({
  projectId,
  drawRequestId,
  json,
}: BulkDrawRequestListItemUpdateParam): Promise<Response> =>
  dataProvider
    .put(milestoneBulkUpdate(projectId, drawRequestId), {
      json,
    })
    .json();

export const getDrawRequestDocumentsList = async ({
  projectId,
  drawRequestId,
  stringQueryParams,
}: {
  projectId: string;
  drawRequestId: string;
  stringQueryParams: string;
}): Promise<{ results: IDocument[]; count: number }> =>
  dataProvider.get(drawRequestDocuments(projectId, drawRequestId, stringQueryParams)).json();

export const getDrawRequestMilestone = async ({
  projectId,
  drawRequestId,
  milestoneId,
  restQlParams,
}: {
  projectId: string;
  drawRequestId: string;
  milestoneId: string;
  restQlParams?: string;
}): Promise<IMilestone> =>
  dataProvider
    .get(requestMilestoneById(projectId, drawRequestId, milestoneId, restQlParams))
    .json();

export const getDrawRequestMilestoneGroup = async ({
  projectId,
  drawRequestId,
  milestoneId,
  group_by,
  restQlParams,
}: {
  projectId: string;
  drawRequestId: string;
  milestoneId: string;
  group_by: string;

  restQlParams?: string;
}): Promise<IMilestone> =>
  dataProvider
    .get(
      requestMilestoneGroupById({ projectId, drawRequestId, milestoneId, group_by, restQlParams }),
    )
    .json();

export const deleteDrawRequestDocument = async ({
  projectId,
  drawRequestId,
  documentId,
}: ChangeDrawRequestDocumentParam): Promise<Response> =>
  dataProvider.delete(drawRequestDocument(projectId, drawRequestId, documentId)).json();

export const restoreDrawRequestDocument = async ({
  projectId,
  drawRequestId,
  documentId,
}: ChangeDrawRequestDocumentParam): Promise<Response> =>
  dataProvider.post(drawRequestDocumentRestore(projectId, drawRequestId, documentId)).json();

export const updateDRDocument = async ({
  projectId,
  drawRequestId,
  documentId,
  json,
}: UpdateDrawRequestListItemParam & { drawRequestId: string }): Promise<Response> =>
  dataProvider.put(drawRequestDocument(projectId, drawRequestId, documentId), {
    json,
  });

export const submitDrawRequest = ({
  project,
  drawRequest,
  status = 'APPROVE',
  ...inspectionData
}: SubmitDrawrequestRequestPayload): Promise<Response> =>
  dataProvider
    .post(postReviewPath(project, drawRequest), {
      json: { status, ...inspectionData },
    })
    .json();

export const deleteDrawRequest = ({ project, drawRequest }: RequestPayload): Promise<Response> =>
  dataProvider.delete(drawRequestItem({ projectId: project, drawRequestId: drawRequest })).json();

export const updateDrawRequestReviewer = ({
  project,
  drawRequest,
  current_reviewer_team,
}: UpdateRequestPayload): Promise<Response> =>
  dataProvider
    .put(drawRequestItem({ projectId: project, drawRequestId: drawRequest }), {
      json: { current_reviewer_team },
    })
    .json();

export const updateDrawRequestCoordinator = ({
  project,
  drawRequest,
  coordinator,
}: UpdateRequestPayload): Promise<Response> =>
  dataProvider
    .put(drawRequestItem({ projectId: project, drawRequestId: drawRequest }), {
      json: { coordinator },
    })
    .json();

export const updateDrawRequestData = ({
  project,
  drawRequest,
  submitted_at,
  approved_at,
  counter_per_request_type,
  inspector_allowance_rate,
  estimated_disbursement_date,
  disbursed_at,
}: UpdateRequestDataPayload): Promise<IDrawRequest> => {
  return dataProvider
    .put(drawRequestItem({ projectId: project, drawRequestId: drawRequest }), {
      json: {
        ...(submitted_at && { submitted_at }),
        ...(approved_at && { approved_at }),
        ...(isNumber(counter_per_request_type) && { counter_per_request_type }),
        ...(!isNaN(+inspector_allowance_rate) && { inspector_allowance_rate }),
        ...(estimated_disbursement_date && { estimated_disbursement_date }),
        ...(disbursed_at && { disbursed_at }),
      },
    })
    .json();
};

export const markRequestAsPaid = ({
  project,
  drawRequest,
  settled_at,
  external_transaction_id,
}: MarkRequestAsPaidPayload): Promise<Response> =>
  dataProvider
    .post(markRequestAsPaidPath(project, drawRequest), {
      json: {
        ...(settled_at && { settled_at }),
        ...(external_transaction_id && { external_transaction_id }),
      },
    })
    .json();

export const postItemToRequest = ({
  index,
  project,
  drawRequest,
  name,
  original_estimate,
  requested_revised_estimate,
  approved_adjustments,
  cost_type,
  is_vertical,
}: LineItemPostPayload): Promise<LineItemPostResponse> =>
  dataProvider
    .post(drawRequestMilestones(project, drawRequest), {
      json: {
        ...(index && { index }),
        ...(cost_type && { cost_type }),
        ...(name && { name }),
        ...(requested_revised_estimate && { requested_revised_estimate }),
        ...(approved_adjustments && { approved_adjustments }),
        ...(original_estimate && { original_estimate }),
        ...(is_vertical && { is_vertical }),
      },
    })
    .json();

export const getDrawRequestPhotos = async (
  projectId: string,
  drawRequestId: string,
): Promise<IMilestonePhotos[]> =>
  dataProvider.get(drawRequestPhotos(projectId, drawRequestId)).json();

export const getDrawRequestMilestonesWithPhotos = async (
  projectId: string,
  drawRequestId: string,
  queryParams: string,
): Promise<{ results: IMilestoneWithPhotos[]; count: number }> =>
  dataProvider.get(drawRequestMilestonesWithPhotos(projectId, drawRequestId, queryParams)).json();

export const deleteRequestMilestoneItem = ({
  project,
  milestone,
  drawRequest,
}: DeleteDrawRequestListItem): Promise<Response> =>
  dataProvider.delete(requestMilestoneById(project, drawRequest, milestone));

export const deleteDrawRequestPhoto = ({
  projectId,
  photoId,
}: DeletePhotoPayload): Promise<Response> =>
  dataProvider.delete(projectPhotoById(projectId, photoId)).json();

export const getProjectAuditLog = async (
  projectId: string,
  queryURL: string,
): Promise<{ results: IActivityLogItem[]; count: number }> =>
  dataProvider.get(projectAuditLog(projectId, queryURL)).json();

export const getDrawRequestAuditLog = async (
  drawRequestId: string,
): Promise<{ results: IActivityLogItem[] }> =>
  dataProvider.get(drawRequestAuditLog(drawRequestId)).json();

export const getMilestoneAuditLog = async (
  milestoneId: string,
  queryURL: string,
): Promise<{ results: IActivityLogItem[]; count: number }> =>
  dataProvider.get(milestoneAuditLog(milestoneId, queryURL)).json();

export const getRequestReviews = async ({
  projectId,
  drawRequestId,
  restQlParams = '',
}: {
  projectId: string;
  drawRequestId: string;
  restQlParams?: string;
}): Promise<{ results: IRequestReview[] }> =>
  dataProvider.get(requestReviews(projectId, drawRequestId, restQlParams)).json();

export const getRequestMilestoneGroupDocuments = async ({
  projectId,
  drawRequestId,
  milestoneId,
  query,
  stringQueryParams,
}: {
  projectId: string;
  drawRequestId: string;
  milestoneId: string;
  query?: string;
  stringQueryParams: string;
}): Promise<{ results: IDocument[]; count: number }> =>
  dataProvider
    .get(milestoneGroupDocuments(projectId, drawRequestId, milestoneId, query, stringQueryParams))
    .json();

export const getMilestoneDocumentsV2 = async ({
  projectId,
  drawRequestId,
  milestoneId,
  query,
  stringQueryParams,
}: {
  projectId: string;
  drawRequestId: string;
  milestoneId: string;
  query?: string;
  stringQueryParams: string;
}): Promise<{ results: IDocument[]; count: number }> =>
  dataProviderV2
    .get(milestoneDocuments(projectId, drawRequestId, milestoneId, query, stringQueryParams))
    .json();

export const getDrawRequestSources = async (
  projectId: string,
  drawRequestId: string,
): Promise<{ results: IDrawRequestFundingSource[] }> =>
  dataProvider.get(drawRequestSources(projectId, drawRequestId)).json();

export const updateDrawRequestFundingSource = async ({
  projectId,
  drawRequestId,
  fundingSourceId,
  json,
}: UpdateDrawRequestFSParam): Promise<Response> =>
  dataProvider.patch(drawRequestFundingSource(projectId, drawRequestId, fundingSourceId), { json });
