import React from 'react';
import { isAllowed, isPercentAllowed, percentFormatter } from '@utils';

import { PercentsInput } from '@components';
import { PermissionNamesEnums, TableKeyEnum } from '@interfaces';
import { MilestoneListColumnTypeV2 } from '../columnsV2/common';
import { GridEditInputCell } from '@mui/x-data-grid';
import { InputBaseComponentProps } from '@mui/material';

const inspectorAllowanceRate: MilestoneListColumnTypeV2 = {
  field: 'inspector_allowance_rate',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Inspection allowance (%)',
  description: 'Onsite completion percentage',
  valueParser: (value) => +value,
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputProps={{
        isAllowed: isPercentAllowed,
      }}
      inputComponent={PercentsInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  minWidth: 120,
  editableByMilestone: (_row, tableKey) =>
    tableKey === TableKeyEnum.INSPECTION_ENTER_RESULTS ||
    tableKey === TableKeyEnum.PHB_INSPECTION_ENTER_RESULTS,
  editableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
};

export default inspectorAllowanceRate;
