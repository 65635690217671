import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import find from 'lodash/find';
import map from 'lodash/map';
import {
  autofillCanBeShown,
  canDeleteRequest,
  checkIfHistoricalRequestEditable,
  checkIsCreator,
  checkIsOwner,
  checkIsReallocateComplete,
  checkIsReallocateCompleteByLender,
  getHookState,
  getItemLocalHighlight,
  getRequestRetainageRate,
  getTeamRole,
  getTooltipText,
  isDrawRequest,
  isReallocationEnabled,
  isRequestDraftOrInReview,
  isRequestInReview,
  isAllowed,
  parsePathErrorDual,
  isRequestLumpSum,
  canAddNewLine,
} from '@utils';
import {
  AuthContext,
  PermissionsContext,
  SettingsContext,
  useLaunchDarklyFlags,
  useGraphQuery,
} from '@context';
import {
  BulkDrawRequestListItemUpdateParam,
  ErrorDual,
  EventType,
  IDrawRequest,
  ILineItemModal,
  IMilestone,
  LineItemModalTypeEnums,
  LineItemPostResponse,
  MutationKeyEnum,
  PermissionNamesEnums,
  QueryNamesEnums,
  RequestPayload,
  TableKeyEnum,
} from '@interfaces';
import {
  deleteDrawRequest,
  getDrawRequest,
  getDrawRequestMilestones,
  getProjectDrawRequestsList,
  postDrawRequestReport,
  postItemToRequest,
  updateDrawRequestMilestones,
} from '@globalService';

import {
  defaultDrawRequestQueryDetails,
  excludeCommentsQueryFields,
  LineItemFilterValues,
} from '@constants';

import {
  useCommentsAndDocumentsPreview,
  useContinueDraftButton,
  useImagePicker,
  useLineItemsFilter,
  useRequestTotals,
  useReviewRequestInvalidation,
  useRightMenu,
  useSafeSnackbar,
  useMessageLinkParams,
} from '@hooks';
import { ControllerInterface, UpdateQueriesParams } from './interface';

export const useDrawRequest = (projectId: string, drawRequestId: string): ControllerInterface => {
  const [openLumpSumModal, setOpenLumpSumModal] = useState(false);
  const handleRequestReviewInvalidation = useReviewRequestInvalidation();

  const [showPrintPlate, setShowPrint] = useState<boolean>(false);
  const [confirmModalType, setConfirmModalType] = useState<string>('');
  const queryClient = useQueryClient();
  const { isCurrentProjectArchived, isPHBProject, isCurrentProjectActive } =
    useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const navigate = useNavigate();

  // if user was redirected from comment deep link, we need to show right drawer
  const { isMessageLink, params } = useMessageLinkParams();
  const [rightDrawerParams, setRightDrawerParams] = useState<{
    projectId?: string;
    requestId?: string;
    milestoneId?: string;
  }>({
    projectId,
    requestId: drawRequestId,
  });
  useEffect(() => {
    if (isMessageLink) {
      setRightDrawerParams({ ...rightDrawerParams, milestoneId: params?.milestoneId });
      updateRightDrawer()();
    }
  }, [isMessageLink]);

  const {
    filterValue,
    handleFilterClick,
    defaultOptions,
    isMilestoneMutatingOrFetching,
    filterKey,
  } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.REQUEST_LINE_ITEMS,
  });

  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { enqueueSnackbar } = useSafeSnackbar();

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    drawRequestId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const updateRightDrawer = () => () => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
  };

  const flags = useLaunchDarklyFlags();

  const optionsList = useMemo(() => defaultOptions, [defaultOptions]);

  const totalKey = useMemo(
    () => find(defaultOptions, { filterValue })?.totalKey || 'all',
    [filterValue],
  );
  const { refetchAndReplaceTotals } = useRequestTotals({
    projectId,
    drawRequestId,
    totalKey,
    filterKey,
  });

  const [lineItemModal, setLineItemModal] = useState<ILineItemModal>({
    open: false,
    type: LineItemModalTypeEnums.ADD,
    lineItem: null,
  });

  const project = useGraphQuery({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'status', 'is_reallocation_enabled', 'is_advanced_budget_tracking_enabled'],
    args: { project_id: projectId },
    options: {
      skip: !projectId,
    },
  });

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
    { enabled: !!projectId },
  );
  const [drawRequestData, milestonesList] = useQueries([
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST,
        {
          projectId,
          drawRequestId,
          query: `{*,${defaultDrawRequestQueryDetails},totals{${totalKey}}}`,
        },
      ],
      queryFn: getDrawRequest.bind(this, {
        projectId,
        drawRequestId,
        query: `{*,${defaultDrawRequestQueryDetails},totals{${totalKey}}}`,
      }),
      enabled: Boolean(drawRequestId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
        { projectId, drawRequestId, filterKey },
      ],
      queryFn: getDrawRequestMilestones.bind(this, { projectId, drawRequestId, filterKey }),
      enabled: Boolean(drawRequestId && !isPHBProject),
    },
  ]);

  const isLumpSumApprovalAvailable = useMemo(
    () =>
      isRequestLumpSum(drawRequestData.data) &&
      isAllowed(PermissionNamesEnums.DRAWREQUESTS_APPROVE, permissions) &&
      isRequestInReview(drawRequestData.data?.status),
    [drawRequestData.data, permissions],
  );

  const isApproveReallocateComplete = useMemo(
    () => checkIsReallocateCompleteByLender(drawRequestData.data),
    [drawRequestData.data],
  );
  const isRequestReallocateComplete = useMemo(
    () => checkIsReallocateComplete(drawRequestData.data),
    [drawRequestData.data],
  );

  const retainageRate = useMemo(
    () => getRequestRetainageRate(drawRequestData?.data),
    [drawRequestData],
  );

  const isHistoricalRequestEditable = useMemo(
    () =>
      checkIfHistoricalRequestEditable({
        request: drawRequestData.data,
        project: project.data,
        projectRequestsList: drawRequestsQuery.data?.results,
      }),
    [drawRequestData.data, project.data, drawRequestsQuery.data?.results],
  );

  const activeToEdit = useMemo(
    () =>
      (isAllowed(PermissionNamesEnums.DRAWREQUESTS_EDIT, permissions) &&
        drawRequestData.data?.waits_current_user_approval &&
        !checkIsOwner(teamRole)) ||
      (isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions) &&
        isRequestInReview(drawRequestData.data?.status)) ||
      isHistoricalRequestEditable,
    [drawRequestData.data, teamRole, isHistoricalRequestEditable],
  );

  const isReallocationAllowed = useMemo(
    () => isReallocationEnabled(drawRequestData.data, project.data),
    [drawRequestData.data, project.data],
  );

  const initColumns = useMemo(() => {
    if (!drawRequestData?.data?.type) return [];
    return [
      'nameV2',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.DRAW_REQUEST}`] ? ['externalId'] : []),
      'costType',
      'originalConstructionBudget',
      'prefundingCost',
      'originalEstimate',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.DRAW_REQUEST}`]
        ? ['revisedConstructionBudgetInReview']
        : ['revisedConstructionBudget']),
      'previousChanges',
      'previousChangesRate',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.DRAW_REQUEST}`]
        ? ['revisedEstimateInReview']
        : ['revisedEstimate']),

      ...(isReallocationAllowed
        ? [
            ...(flags?.['ENG_9922_allow_lender_to_change_request_amounts']
              ? ['requestedAdjustmentsV2']
              : ['requestedAdjustments']),
            'requestedAdjustmentsRate',
            'approvedAdjustments',
            'adjustmentsRate',
            'requestedRevisedEstimate',
          ]
        : []),
      ...(isDrawRequest(drawRequestData?.data)
        ? [
            ...(flags?.['ENG_9922_allow_lender_to_change_request_amounts']
              ? ['requestedAmountV2']
              : ['requestedAmount']),
            'requestedAmountRelative',
          ]
        : []),
      'inspectorAllowance',
      'inspectorAllowanceRate',
      'inspectorAllowanceIncremental',
      'inspectorAllowanceRateIncremental',
      'approvedAmountCumulative',
      'lenderAllowanceRate',
      ...(isDrawRequest(drawRequestData?.data) ? ['approvedAmount', 'approvedAmountRelative'] : []),
      'previousApprovedAmountCumulative',
      'previousLenderAllowanceRate',
      ...(flags?.[`ENG_7895_table_v3__${TableKeyEnum.DRAW_REQUEST}`]
        ? ['balanceToFinishInReview']
        : ['balanceToFinish']),
      'balanceToFinishRate',
      ...(retainageRate
        ? [
            'retainageBalanceTodateApproved',
            'retainageApprovedHoldback',
            'retainageReleaseRequested',
            'retainageReleaseApproved',
          ]
        : []),
      'varianceToLenderAllowance',
      'varianceToLenderAllowanceRate',
      ...(flags?.['ENG_9473_line_item_tags'] ? ['lineItemTags'] : []),
      ...(isDrawRequest(drawRequestData?.data) ? ['approveCredit'] : []),
      ...(isAllowed(PermissionNamesEnums.DRAWREQUESTS_APPROVE, permissions) &&
      flags?.['ENG_9511_increase_budget_from_funding_source']
        ? ['lineItemDetails']
        : []),
      ...(flags?.[`ENG_10310_delete_line_item`] ? ['deleteLineItem'] : []),
      'documentsPhotosUploaderMenuV2',
      'documentsPhotosGalleryMenuV2',
      'comments',
    ];
  }, [drawRequestData?.data, flags, retainageRate, activeToEdit, isReallocationAllowed]);

  const updateQueries = async (params: UpdateQueriesParams) => {
    if (!params?.skipRefetchTotals)
      refetchAndReplaceTotals({ isMilestonesUpdate: params?.isMilestonesUpdate });
    try {
      Promise.all([
        ...(params?.withRequest
          ? [
              queryClient.invalidateQueries([
                QueryNamesEnums.GET_DRAW_REQUEST,
                { projectId, drawRequestId },
              ]),
              queryClient.invalidateQueries([
                QueryNamesEnums.GET_DRAW_REQUEST_V2,
                { project_id: projectId, draw_request_id: drawRequestId },
              ]),
              queryClient.invalidateQueries([
                QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
                { projectId, drawRequestId },
              ]),
              queryClient.invalidateQueries([
                QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
                { projectId, requestId: drawRequestId },
              ]),
            ]
          : []),
        //TODO remove GET_PROJECT_PHOTOS with ENG_9658_new_photo_tab
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId }]),
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES_WITH_PHOTOS,
          { projectId },
        ]),

        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL,
          { projectId },
        ]),
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === QueryNamesEnums.GET_DRAW_REQUEST &&
            (query.queryKey[1] as { projectId?: string })?.projectId == projectId,
        }),
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === QueryNamesEnums.GET_DRAW_REQUEST_V2 &&
            (query.queryKey[1] as { project_id?: string })?.project_id == projectId,
        }),
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]),
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST,
          { projectId },
        ]),
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES,
          { projectId, query: excludeCommentsQueryFields },
        ]),
      ]);
    } catch (error) {
      enqueueSnackbar('Update Error', { variant: 'error' });
    }
  };

  const milestones = useMemo(() => {
    if (!drawRequestData.data || !milestonesList.data?.results) return [];

    return milestonesList.data.results?.map((item) => ({
      ...item,
      localIsUserCreator: checkIsCreator(drawRequestData.data, teamRole),
      localNew: item.milestone_is_new_for_current_draw,
      localHighlight: getItemLocalHighlight(item),
      canAddPhotos: isRequestDraftOrInReview(drawRequestData.data?.status),
      isRequestHistorical: isHistoricalRequestEditable,
      activeToEdit,
      disabled: {
        value: isCurrentProjectArchived,
        reason: getTooltipText({ isCurrentProjectArchived }),
      },
      // TODO: remove these fields after export to csv will be implemented at BE ENG-6131
      original_construction_budget: item.prefunding_cost + item.original_estimate,
      revised_construction_budget: isRequestInReview(drawRequestData.data?.status)
        ? item.prefunding_cost + item.revised_estimate - item.requested_adjustments
        : item.prefunding_cost + item.revised_estimate,
    }));
  }, [
    drawRequestData.data,
    filterValue,
    teamRole,
    project.data,
    activeToEdit,
    milestonesList.data,
    isCurrentProjectArchived,
  ]);

  // bulk milestones update on autofill
  const bulkMilestoneMutation = useMutation<
    Response,
    ErrorDual,
    BulkDrawRequestListItemUpdateParam
  >(updateDrawRequestMilestones, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH_BULK,
    onSuccess: () => {
      updateQueries({ withRequest: true, skipRefetchTotals: false });
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  const handleAutofillLenderAllowance = (autofillValue) => {
    const json = {
      autofill_key: autofillValue,
    };
    bulkMilestoneMutation.mutate({
      projectId,
      drawRequestId,
      json,
    });
  };

  const totals = useMemo(
    () => ({
      ...drawRequestData.data?.totals?.[find(optionsList, { filterValue })?.totalKey],
      isFiltered: filterValue !== LineItemFilterValues.ALL.filterValue,
    }),
    [filterValue, drawRequestData.data],
  );

  const showAutofillButton = useMemo(
    () => autofillCanBeShown(drawRequestData.data, teamRole),
    [drawRequestData?.data, teamRole],
  );

  const canViewReport = useMemo(
    () =>
      isDrawRequest(drawRequestData.data) &&
      isAllowed(PermissionNamesEnums.PROJECTS_REPORT_VIEW, permissions),
    [permissions, drawRequestData.data],
  );

  const isCommentsAvailable = useMemo(
    () => isAllowed(PermissionNamesEnums.COMMENTS_VIEW, permissions),
    [permissions],
  );

  const addLineListItemMutation = useMutation<LineItemPostResponse, Error, RequestPayload>(
    postItemToRequest,
    {
      mutationKey: MutationKeyEnum.DRAW_REQUEST_ADD_ITEM,
      onSuccess: () => {
        updateQueries({ withRequest: true, skipRefetchTotals: false });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const addLineList = useCallback(() => {
    setLineItemModal({
      open: true,
      type: LineItemModalTypeEnums.ADD,
      lineItem: null,
      fields: ['name', 'approved_adjustments'],
      submitAction: 'addToRequest',
    });
  }, []);

  const { openDraft, continueDraftButtonLabel } = useContinueDraftButton({
    projectId,
    drawRequestId,
    drawRequest: drawRequestData.data,
  });

  const showAddNewLine = useMemo(
    () =>
      canAddNewLine({
        drawRequest: drawRequestData.data,
        permissions,
        teamRole,
        isReallocationAllowed,
      }),
    [drawRequestData.data, permissions, teamRole, isReallocationAllowed],
  );

  const deleteDrawRequestMutation = useMutation<Response, Error, RequestPayload>(
    deleteDrawRequest,
    {
      mutationKey: MutationKeyEnum.MILESTONE_DELETE,
      onSuccess: async (data, vars) => {
        enqueueSnackbar('Request deleted', { variant: 'success' });
        navigate(`/projects/${projectId}/overview`);
        handleRequestReviewInvalidation({
          projectId,
          drawRequestId: vars?.drawRequest,
          event_type: EventType.DRAW_REQUEST_DELETED,
        });
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        setConfirmModalType('');
      },
    },
  );

  const deleteRequest = useCallback(
    () =>
      deleteDrawRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: drawRequestId,
      }),
    [deleteDrawRequestMutation, projectId, drawRequestId],
  );

  const reportCallback = useCallback(
    async (e: React.MouseEvent) => {
      try {
        e?.stopPropagation?.();
        await postDrawRequestReport({ project: projectId, drawRequest: drawRequestId });
        enqueueSnackbar('Report generation has started. Check "Documents" tab in a few minutes.', {
          variant: 'info',
        });
      } catch (e) {
        console.log({ e });
        enqueueSnackbar('Generate Error', { variant: 'error' });
      }
    },
    [drawRequestId, projectId],
  );

  const { pdf, open, close } = useImagePicker();

  const openPdfReport = useCallback(() => {
    open([drawRequestData.data.report]);
  }, [open, drawRequestData.data]);

  const openEditMilestoneModal = useCallback(
    (lineItem: IMilestone) => {
      setLineItemModal({
        open: true,
        type: LineItemModalTypeEnums.EDIT,
        lineItem,
        fields: ['name', 'cost_type', 'requested_revised_estimate'],
        submitAction: 'editInRequest',
      });
    },
    [setLineItemModal],
  );

  return {
    state: getHookState([drawRequestData, project]),
    updateQueries,
    totals,
    initColumns,
    drawRequest: drawRequestData.data,
    milestones,
    isInReview: isRequestInReview(drawRequestData.data?.status),
    handleAutofillLenderAllowance,
    handleFiltersChange: handleFilterClick,
    filterOptions: map(optionsList, 'filterValue'),
    showAutofillButton,
    isApproveReallocateComplete,
    isRequestReallocateComplete,
    showPrintPlate,
    setShowPrint,
    canViewReport,
    addLineList,
    openDraft,
    showAddNewLine,
    creatingNewLine: addLineListItemMutation.isLoading,
    canDeleteRequest: canDeleteRequest(drawRequestData.data),
    deleteRequest,
    pdf,
    close,
    openPdfReport,
    reportCallback,
    isDeleting: deleteDrawRequestMutation.isLoading,
    isAutofillLoading: bulkMilestoneMutation.isLoading,
    confirmModalType,
    setConfirmModalType,
    lineItemModal,
    setLineItemModal,
    openEditMilestoneModal,
    filterValue,
    isMilestoneMutatingOrFetching,
    isCurrentProjectActive,
    projectName: project.data?.name,
    openPdfViewer: (file) => open([file]),
    continueDraftButtonLabel,
    updateRightDrawer,
    rightMenu,
    isRequestDraftOrInReview: isRequestDraftOrInReview(drawRequestData.data?.status),
    openLumpSumModal,
    setOpenLumpSumModal,
    isLumpSumApprovalAvailable,
    isCommentsAvailable,
    filterKey,
    rightDrawerParams,
  };
};
