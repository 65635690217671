import React, { FC } from 'react';
import { DataTableV3, MilestoneListOptionsMenu } from '@components';
import { useMilestoneList } from './controllerV2';
import { MilestoneListInterface } from './interface';
import { MilestroneListDrawer } from './drawer';
import ReviewCredit from './ReviewCredit';
import { MilestoneListItem } from './columns/common';

const MilestoneList: FC<MilestoneListInterface> = ({
  tableKey,
  milestones,
  initColumns,
  totals,
  withColumnIndication,
  headerLeftPart = [],
  headerRightPart = [],
  patchMilestone,
  refetch,
  deleteMilestone,
  openEditMilestoneModal,
  contingencyMode,
  source,
  updateData,
  withCredit,
  groupByFilter,
  onCreditChangeCompleted,
  commentIconComponent,
  menuItems = [],
  exportToCSV,
  rowReordering,
  bulkUpdateApi,
  containerSpacing,
}) => {
  const {
    sortedMilestones,
    columns,
    columnsToCSV,
    milestoneColumns,
    apiUpdateV2,
    totalCalculation,
    drawRequestErrors,
    showCreditButton,
  } = useMilestoneList({
    tableKey,
    milestones,
    initColumns,
    patchMilestone,
    withColumnIndication,
    contingencyMode,
    source,
    updateData,
    withCredit,
    groupByFilter,
    totals,
  });

  return (
    <MilestroneListDrawer
      refetch={refetch}
      deleteMilestone={deleteMilestone}
      openEditMilestoneModal={openEditMilestoneModal}
      commentsPreviewQueryEnabled={!!sortedMilestones.length}
      milestones={sortedMilestones}
      tableKey={tableKey}
      source={source}
      onCreditChangeCompleted={onCreditChangeCompleted}
    >
      <DataTableV3
        withProgressiveColumns
        disableRowSelectionOnClick
        containerSpacing={containerSpacing}
        withColumnIndex
        bulkUpdateApi={bulkUpdateApi}
        rowReordering={rowReordering}
        withCustomColumnPicker
        headerLeft={headerLeftPart}
        headerRight={[
          ...(showCreditButton
            ? [
                {
                  Component: <ReviewCredit />,
                },
              ]
            : []),
          ...headerRightPart,
          {
            Component: commentIconComponent,
          },
        ]}
        dotsMenu={
          <MilestoneListOptionsMenu
            menuItems={menuItems}
            totals={totals}
            columns={columnsToCSV as Partial<MilestoneListItem>[]}
            sortedMilestones={sortedMilestones}
            exportToCSV={exportToCSV}
          />
        }
        rowUpdateApi={apiUpdateV2}
        tableKey={tableKey}
        rows={sortedMilestones}
        columns={columns}
        totals={totalCalculation}
        errors={{
          rowErrors: drawRequestErrors,
          columnErrors: milestoneColumns,
        }}
      />
    </MilestroneListDrawer>
  );
};

export default MilestoneList;
